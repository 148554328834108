import React from "react";

const Error404 = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        background: `linear-gradient(to right, #222831, #393E46)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#EEEEEE",
        padding: "1rem",
      }}
    >
      <img
        style={{ height: "50vh", width: "65vh" }}
        src={require("../Images/Error404.png")}
        alt=""
      />
      <p
        style={{ fontSize: "2rem", marginBottom: "2rem", textAlign: "center" }}
      >
        Oops! The page you are looking for cannot be found.
      </p>
      <button
        style={{
          display:"flex",
          alignItems:"center",
          background: "#00ADB5",
          color: "#EEEEEE",
          border: "none",
          padding: "0.5rem 1rem",
          borderRadius: "10px",
          fontSize: "1rem",
          cursor: "pointer",
          transition: "background-color 0.3s ease-in-out",
          boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
        }}
        onClick={goBack}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "#00CCD6";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#00ADB5";
        }}
      >
        Go back
      </button>
      <p style={{ marginTop: "3rem" }}>
        Alternatively, you can go to the{" "}
        <span
          style={{
            fontSize: "1rem",
            textDecoration: "underline",
            fontWeight: "bold",
            textShadow: "2px 2px 4px #00ADB5",
          }}
        >
          <a href="/">homepage</a>.
        </span>
      </p>
    </div>
  );
};

export default Error404;
