import React from "react";
import { NavLink } from "react-router-dom";
import "./header.scss";

const resumeURL="https://viveksingh.tech/Vivek_kumar_singh.pdf"
export default function Header() {
  const downloadResume= (url)=>{
    const fileName=url.split('/').pop();
    const aTag=document.createElement("a");
    aTag.href=url;
    aTag.setAttribute("download",fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  }
  return (
    <div className="navbar bg-base-100 sticky top-0">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden" aria-label="Toggle menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              alt="logo"
            >

              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li>
            <NavLink  to={"/resume"}>Resume</NavLink>
          </li>
            <li tabIndex={0}>
              <NavLink to={"/projects"} className="justify-between">
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact"}>Contact Me</NavLink>
            </li>
          </ul>
        </div>
        <NavLink to={"/"} className="btn btn-ghost normal-case text-xl">
          Vivek Singh
        </NavLink>
      </div>
      <div className="nav_menu navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <NavLink className="mx-4" to={"/"}>Home</NavLink>
          </li>
          <li>
            <NavLink className="mx-4" to={"/resume"}>Resume</NavLink>
          </li>
          <li tabIndex={0}>
            <NavLink className="mx-4" to={"/projects"}>Projects</NavLink>
          </li>
          <li>
            <NavLink className="mx-4" to={"/contact"}>Contact Me</NavLink>
          </li>
        </ul>
      </div>
      <div className="navbar-end">
        <button onClick={()=>{downloadResume(resumeURL)}} class="cool-button btn" href="src/Vivek kumar singh.pdf"><i class='bx bxs-file-doc bx-sm'></i></button>
      </div>
    </div>
  );
}
