import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import VisitorCountContext from './VisitorCountContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Index() {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    fetch('https://api.countapi.xyz/hit/viveksingh.tech/visits').then(res => {
      res.json().then(data => {
        setVisitorCount(data.value);
      })
    });
  }, []);

  return (
    <VisitorCountContext.Provider value={visitorCount}>
      <App />
    </VisitorCountContext.Provider>
  );
}

root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);
