import React, { useContext } from "react";
import VisitorCountContext from "../VisitorCountContext";

export default function Footer() {
  const visitorCount = useContext(VisitorCountContext);
  return (
    <footer className="footer footer-center p-4 bg-base-300 text-base-content mt-6">
      <div>
        {/* <p>Copyright © 2023 - All right reserved by ACME Industries Ltd  || Total Visits = <span id="count">{visitorCount}</span></p> */}
        <p>
          Copyright © 2024 - All right reserved by ACME Industries Ltd{" "}
          <span>{visitorCount}</span>{" "}
        </p>
      </div>
    </footer>
  );
}
