import React , { useRef ,useState } from "react";
import Header from "./Header/Header";
import "../style/Contact.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();
  const [curr, Update] = useState({
    user_fname:"",
    user_lname:"",
    user_email:"",
    user_phone:"",
    message:"",
  })
  const UpdateValue = (e) => {
    const value = e.target.value;
    const name=e.target.name;
    Update((prev)=>{
      return{
        ...prev,[name]:value
      }
    })
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const sendEmail = async (e) => {
    console.log("i am clickeds")
    try {
      e.preventDefault();
      const { user_fname, user_lname, user_email, user_phone, message } = curr;

      if (!user_fname || !user_lname || !user_email || !user_phone || !message) {
        toast.error('All fields are required .!');
        return;
      }

      if (!emailRegex.test(user_email)) {
        toast.error('Enter valid Email');
        return;
      }
      
      const messageWithoutSpaces = message.replace(/\s/g, '');
      const numLetters = messageWithoutSpaces.length;
      if(numLetters>1000 || numLetters<5){
        toast.warning('Message must be more than 5 and less than 1000 characters');
        return;
      }
      await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      );
      console.log('Message Sent Successfully');
      toast.success('Message Sent Successfully');
      Update({
        user_fname: '',
        user_lname: '',
        user_email: '',
        user_phone: '',
        message: '',
      });
    } catch (error) {
      console.log(error.text);
    }
  };
  
  
  return (
    <>
      <Header />
      <div className="contacts">
        <div className="contact-container">
          <div className="contactInfo">
            <div>
              <h2>Contact Info</h2>
              <ul className="info">
                <li>
                  <span>
                  <i className='bx bx-map bx-sm'></i>
                  </span>
                  <span>
                    Sector 15 , Chandigarh
                    <br />
                     Punjab , India
                  </span>
                </li>
                <li>
                  <span>
                  <i className='bx bx-envelope bx-sm' ></i>
                  </span>
                  {/* <!-- <span>cu.vivek.1557@gmail.com.com</span> --> */}
                  <span>
                    <a href="mailto: cu.vivek.1557@gmail.com">
                      cu.vivek.1557@gmail.com
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                  <i className='bx bx-phone bx-sm'></i>
                  </span>
                  <span>788-870-9597</span>
                </li>
              </ul>
            </div>
            <ul className="sci">
            <li>
                <a href="https://github.com/ui-vivek">
                <i className='bx bxl-github bx-sm' ></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/ui_vivek/">
                <i className='bx bxl-instagram-alt bx-sm' ></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Vivek_singh_25">
                <i className='bx bxl-twitter bx-sm' ></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/uivivek/">
                <i className='bx bxl-linkedin-square bx-sm' ></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="contactForm">
            <h2>Hire Me</h2>
            <form ref={form} onSubmit={sendEmail}  className="formBox" >
              <div className="inputBox w50">
                <input type="text" value={curr.user_fname} required  minLength={3} name="user_fname"onChange={UpdateValue}/>
                <span>First Name</span>
              </div>
              <div className="inputBox w50">
                <input type="text" value={curr.user_lname} required minLength={3} name="user_lname" onChange={UpdateValue}/>
                <span>Last Name</span>
              </div>
              <div className="inputBox w50">
                <input type="email" value={curr.user_email} required name="user_email"  onChange={UpdateValue}/>
                <span>Email Address</span>
              </div>
              <div className="inputBox w50">
                <input type="text" value={curr.user_phone} required name="user_phone" onChange={UpdateValue}/>
                <span>Mobile Number</span>
              </div>
              <div className="inputBox w100">
                <textarea  name="message" value={curr.message} required onChange={UpdateValue}></textarea>
                <span>Write your message here...</span>
              </div>
              <div className="inputBox w100">
                <input type="submit" value="Send" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" theme="colored" />
    </>
  );
}
