import React from "react";
import Header from "./Header/Header";
import "../style/Resume.scss";
import aws from "../Icons/aws.svg";
import css from "../Icons/css.svg";
import express from "../Icons/express.svg";
import figma from "../Icons/figma.svg";
import git from "../Icons/git.svg";
import html from "../Icons/html.svg";
import java from "../Icons/java.svg";
import js from "../Icons/js.svg";
import mongodb from "../Icons/mongodb.svg";
import node from "../Icons/node.svg";
import postman from "../Icons/postman.svg";
import react from "../Icons/react.svg";
import sass from "../Icons/sass.svg";
import xd from "../Icons/xd.svg";
import wix from "../Icons/wix.svg";
import Footer from "./Footer";
import anguler from "../Icons/anguler.svg";
import docker from "../Icons/docker.svg";
import nest from "../Icons/nest.svg";

export default function About() {
  return (
    <>
      <Header />
      <div className="resume">
      <div className="top">
          <section className="timeline">
            <div className="title-wrapper">
              <div className="icon-box">
                <i
                  style={{ color: "#00ADB5" }}
                  class="bx bx-briefcase-alt-2 bx-md"
                ></i>
              </div>
              <h3 className="font-bold text-2xl">Work</h3>
            </div>

            <ol className="timeline-list">
              <li className="timeline-item">
                <h4 className="font-bold timeline-item-title">
                OnGraph Technologies Pvt. Ltd.
                </h4>
                <span className="font-bolder"> Software Engineering </span>
                {/* <p className="timeline-text">( M * E * A * N )</p> */}
                <p className="italic">Jan-2023 — July-2024</p>
              </li>
              <li className="timeline-item">
              <h4 className="font-bold timeline-item-title">
                Coding Ninja (TAship)
                </h4>
                <span className="font-bolder">Teaching Assistant ( Full Stack Web Development )</span>
                <p className="timeline-text">( M * E * R * N )</p>
                <p className="italic">Jan-2023 — May-2023</p>
              </li>
            </ol>
          </section>
          <section className="image flex items-center">
            <img
              src={require("../Images/work.png")}
              alt=""
            />
          </section>
        </div>
          {/* --------------------------------------Skills */}
          <div className="skills">
          <h1 className="skill-name font-bold text-2xl">My Skills</h1>
          <div className="tooltip tooltip-info" data-tip="nest.JS">
            <img
              className="btn "
              style={{ backgroundColor: "#E4B9C8" }}
              src={nest}
              alt="nest"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="react">
            <img
              className="btn "
              style={{ backgroundColor: "#C9EEFF" }}
              src={react}
              alt="react"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="angular">
            <img
              className="btn "
              style={{ backgroundColor: "#FFC9C9" }}
              src={anguler}
              alt="angular"
            />
          </div>
          
          <div className="tooltip tooltip-info" data-tip="docker">
            <img
              className="btn "
              style={{ backgroundColor: "#A9CBE4" }}
              src={docker}
              alt="docker"
            />
          </div> 
          
          <div className="tooltip tooltip-info" data-tip="aws">
            <img
              className="btn "
              style={{ backgroundColor: "#F5E9CF" }}
              src={aws}
              alt="aws"
            />
          </div>


          <div className="tooltip tooltip-info" data-tip="css">
            <img
              className="btn "
              style={{ backgroundColor: "#7DB9B6" }}
              src={css}
              alt="css"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="express">
            <img
              className="btn "
              style={{ backgroundColor: "#393E46" }}
              src={express}
              alt="express"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="figma">
            <img
              className="btn "
              style={{ backgroundColor: "#D2DAFF" }}
              src={figma}
              alt="figma"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="git">
            <img
              className="btn "
              style={{ backgroundColor: "#FFE7CC" }}
              src={git}
              alt="git"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="html">
            <img
              className="btn "
              style={{ backgroundColor: "#F8CBA6" }}
              src={html}
              alt="html"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="java">
            <img
              className="btn "
              style={{ backgroundColor: "#3C84AB" }}
              src={java}
              alt="java"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="javascript">
            <img
              className="btn "
              style={{ backgroundColor: "#FAFFAF" }}
              src={js}
              alt="javascript"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="mongodb">
            <img
              className="btn "
              style={{ backgroundColor: "#94AF9F" }}
              src={mongodb}
              alt="mongodb"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="node">
            <img
              className="btn "
              style={{ backgroundColor: "#829460" }}
              src={node}
              alt="node"
            />
          </div>

          <div className="tooltip tooltip-info" data-tip="postman">
            <img
              className="btn "
              style={{ backgroundColor: "#D27685" }}
              src={postman}
              alt="postman"
            />
          </div>


          <div className="tooltip tooltip-info " data-tip="sass">
            <img
              className="btn "
              style={{ backgroundColor: "#EBC7E6" }}
              src={sass}
              alt="sass"
            />
          </div>

          <div className="tooltip tooltip-info " data-tip="adobe xd">
            <img
              className="btn "
              style={{ backgroundColor: "#4E31AA" }}
              src={xd}
              alt="adobe xd"
            />
          </div>
          <div className="tooltip tooltip-info " data-tip="wix">
            <img
              className="btn "
              style={{ backgroundColor: "#EEEEEE" }}
              src={wix}
              alt="wix"
            />
          </div>
        </div>
        {/* --------------------------------------------------- Education */}
        <div className="top">
          <section className="timeline">
            <div className="title-wrapper">
              <div className="icon-box">
                <i
                  style={{ color: "#00ADB5" }}
                  class="bx bx-book-open bx-md"
                ></i>
              </div>

              <h3 className="font-bold text-2xl">Education</h3>
            </div>

            <ol className="timeline-list">
              <li className="timeline-item">
                <h4 className="font-bold timeline-item-title">
                  Chandigarh University
                </h4>

                <span className="font-bolder">Bachelor of Engineering </span>

                <p className="timeline-text">(Computer Science Engineering)</p>
                <p className="italic">2018 — 2022</p>
              </li>

              <li className="timeline-item">
                <h4 className="font-bold timeline-item-title">
                  Children Garden School
                </h4>

                <span className="font-bolder">CBSE-12th Boards </span>

                <p className="timeline-text">(PCM)</p>
                <p className="italic">2017 — 2018</p>
              </li>

              <li className="timeline-item">
                <h4 className="font-bold timeline-item-title">
                  D.A.V Public School
                </h4>

                <span className="font-bolder">CBSE-10th Boards </span>

                <p className="italic">2014 — 2015</p>
              </li>
            </ol>
          </section>
          <section className="image flex items-center">
            <img
              src={require("../Images/person-studying-at-a-desk.png")}
              alt=""
            />
          </section>
        </div>

        {/* -----------------------------------------------ACHIEVEMENTS*/}
        <div className="footer-section">
          <div className="Achievement my-2">
            <section className="timeline">
              <div className="title-wrapper">
                <div className="icon-box">
                  <i style={{ color: "#00ADB5" }} class="bx bx-medal bx-md"></i>
                </div>

                <h3 className="font-bold text-2xl">Achievement</h3>
              </div>
              <ol className="timeline-list">
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                    TOP performer in Ethical Hacking <a href="https://trainings.internshala.com/s/v/11775/6d0221a9"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by internshala. </span>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                    TOP performer in UI/UX design <a href="https://trainings.internshala.com/s/v/9389/3fbbea57"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by internshala. </span>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                    Design a User Experience for Social Good & Prepare for Jobs <a href="https://coursera.org/verify/LXKE5GMK44X7"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder"> by Google. </span>
                </li>
              </ol>
            </section>
            <section className="image flex items-center">
              <img src={require("../Images/Achievement.png")} alt="" />
            </section>
          </div>
          {/* ----------------------------------------------------CERTIFICATES */}
          <div className="Certificates my-6">
            <section className="timeline">
              <div className="title-wrapper">
                <div className="icon-box">
                  <i style={{ color: "#00ADB5" }} class="bx bx-certification bx-md"></i>
                </div>
               
                <h3 className="font-bold text-2xl">Certificates</h3>
              </div>
              <ol className="timeline-list">
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                    Google UX Design Professional <a href="https://coursera.org/share/d9ddb76654d6fb854f3a5f7da8db8926"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder"> by Google. </span>
                  <p className="italic">Completed on Feb 2, 2022</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                    Data Structures And Algorithms In Java. <a href="https://certificate.codingninjas.com/view/e64d9caab4b50ef7"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by CodingNinja </span>
                  <p className="italic">Completed on Oct-2022</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                  Front End | Full Stack Web Development <a href="https://certificate.codingninjas.com/view/4c4a08910065a87e"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by CodingNinja. </span>
                  <p className="italic">Completed on Dec-2022</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                  React <a href="https://certificate.codingninjas.com/view/60b019f3b6c91b3b"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by CodingNinja. </span>
                  <p className="italic">Completed on Mar-2023</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                  Back End | Full Stack Web Development in Node.js <a href="https://certificate.codingninjas.com/view/4fca33f465d6bc87"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by CodingNinja. </span>
                  <p className="italic">Completed on Jan-2023</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                  Google Cloud Fundamentals for AWS Professionals <a href="https://coursera.org/share/7a34897f26158707c821241b4d9cee34"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by Coursera. </span>
                  <p className="italic">Completed on Jun-2021</p>
                </li>
                <li className="timeline-item">
                  <h4 className="font-bold timeline-item-title">
                  AWS Identity and Access Management <a href="https://coursera.org/share/d16442268bf95f6e0b8329c53a61eb47"><i class='bx bx-link-external '></i></a>
                  </h4>
                  <span className="font-bolder">by Coursera. </span>
                  <p className="italic">Completed on Jun-2021</p>
                </li>
              </ol>
            </section>
            <section className="image flex items-center">
              <img src={require("../Images/Certificates.png")} alt="" />
            </section>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
